.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.large-number {
  position: relative;
  font-size: 7em;
  color: grey;
  text-align: center;
}

.large-number::before {
  content: "?";
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 290px;
  color: rgba(19, 112, 124, 0.267);
  z-index: 0;
  pointer-events: none;
}

.task-upload {
  width: 900px;
  background-color: white;
  border: 2px solid black;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.upload-text, .task-text {
  margin: 10px 0;
  font-size: 1.2em;
  text-align: center;
}

.task-text {
  font-size: 2em;
  margin-bottom: 20px;
}

.button-fixed {
  position: fixed;
  bottom: 20px; /* Distance from the bottom of the viewport */
  right: 1px; /* Distance from the right of the viewport */
  /* Optional: Styling for the button */
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensures the button appears on top of other elements */
}

.CustomWebcam {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-upload h2 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 2em;
  margin: 5px 0;
  text-align: center;
  padding: 5px 10px;
}

.task-card {
  width: 400px;
  height: 300px;
  background-color: white;
  border: 2px solid black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

 .task-card img {
/*  width: 100%;
  height: 100%;*/
  object-fit: cover;
} 

.GridBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: lightgray;
}

.flex-table {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-cell {
  width: 10rem;
  height: 20rem;
  flex: 1;
  padding: 4px;
  background-color: grey;
  display: flex;
  margin: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 2px;
}

.btn-container {
  display: flex;
  gap: 5px;
  margin-top: 10px 10px;
}

.btn-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.btn-container button {
  padding: 12px 24px;
  font-size: 1em;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-container button:hover {
  background-color: #45a049;
}

.btn-container button:focus {
  outline: none;
}

/* Media Queries for Mobile Devices */
@media only screen and (max-width: 780px) {

  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 10px;
  }

  .large-number {
    font-size: 4em;
  }

  .large-number::before {
    font-size: 90px;
    top: 50%;
  }

  .task-text {
    font-size: 1em;
    margin-bottom: 20px;
  }


  .task-upload {
    width: 90%;
    padding: 20px;
    top: auto;
    left: auto;
    transform: none;
    position: static;
    margin-top: 20px;
  }

  .task-card {
    width: 100%;
    height: auto;
    margin-bottom: 1px;
  }

  .flex-cell {
    width: 100%;
    height: auto;
  }

  .container {
    padding: 1px;
  }

  .btn-container {
    flex-direction: column;
    gap: 1px;
  }

  .btn-container button {
    width: 100%;
    padding: 10px;
  }
}
